import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

export const ColStart = styled.div`
  display: flex;
  flex-direction: column;
  &.stretch {
    align-items: stretch;
  }
  &.end {
    align-items: flex-end;
  }
`

export const ColEnd = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  &.stretch {
    align-items: stretch;
  }
`

export const ColCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.start {
    align-items: flex-start;
  }
`

export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  &.align-center {
    align-items: center;
  }
`

export const RowSideSpacing = styled.div`
  width: 4vw;
`

export const ColSpaceBetween = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &.stretch {
    align-items: stretch;
  }

  &.space-evently {
    justify-content: space-evenly;
  }
`

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &.start {
    align-items: flex-start;
  }
`

export const RowEnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  &.align-center {
    align-items: center;
  }
`

export const RowStart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &.align-start {
    align-items: flex-start;
  }

  .expanded {
    flex: 1;
  }
`

export const ContentStyled = styled.div`
  margin-top: 20px;
  margin-left: 70px;
  // margin-bottom: 30%;

  &.half {
    width: 50%;
  }

  @media screen and (min-width: 2500px) {
    margin-top: 60px;
    margin-left: 70px;
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
    margin-top: 60px;
    margin-left: 70px;

    &.half {
      width: 50%;
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    margin-top: 60px;
    margin-left: 70px;

    &.half {
      width: 50%;
    }
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    margin-top: 60px;
    margin-left: 70px;


    &.half {
      width: 50%;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    margin-top: 60px;
    margin-left: 50px;


    &.half {
      width: 70%;
    }
  }

  @media screen and (max-width: 1023px) {
    margin-top: 60px;
    margin-left: 0px;


    &.half {
      width: calc(100% - 50px);
      margin-top: 60px;
      margin-left: 25px;
      margin-right: 25px;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 60px;
    margin-left: 0px;


    &.half {
      width: calc(100% - 60px);
      margin-top: 40px;
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  @media screen and (max-width: 340px) {
    margin-top: 20px;
    margin-left: 0px;


    &.half {
      width: calc(100% - 20px);
      margin-top: 40px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`



export const HeaderStyled = styled.div`
  font-size: 4vw;
  font-family: "Akira";
  font-weight: bold;
  color: white;
  margin-left: 80px;
  margin-bottom: 15px;

  &.team {
    font-family: "Montserrat";
  }

  &.text-team {
    font-size: 3.3vw;
  }

  @media screen and (min-width: 2500px) {
    margin-left: 50px;
    font-size: 5vw;
    &.text-team {
    }
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
    margin-left: 30px;
    font-size: 5vw;
    &.text-team {
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    margin-left: 30px;
    font-size: 6vw;
    &.text-team {
    }
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    margin-left: 30px;
    font-size: 6vw;
    &.text-team {
      font-size: 4.5vw;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    margin-left: 30px;
    font-size: 7vw;
    &.text-team {
      font-size: 5.5vw;
    }
  }

  @media screen and (max-width: 1023px) {
    margin-left: 55px;
    font-size: 8.5vw;
    &.text-team {
      margin-left: 0px;
      font-size: 5.3vw;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    &.team {
      margin-left: 0px;
      margin-bottom: 0px;
    }

    &.text-team {
      margin-left: 0px;
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    font-size: 7.3vw;
    font-size: 9vw;

    &.text-team {
      margin-left: 0px;
      font-size: 7.3vw;
    }
    &.team {
      margin-left: 0px;
    }
  }


`

export const SubHeaderStyled = styled.h4`
  font-size: 2.3vw;
  font-family: "Montserrat";
  font-weight: bold;
  color: white;
  margin-bottom: 0px !important;
  margin-top: 0px;

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    font-size: 2.3vw;
  }

  @media screen and (min-width: 1023px) and (max-width: 1365px) {
    font-size: 3.3vw;
  }

  @media screen and (min-width: 769px) and (max-width: 1022px) {
    font-size: 4.3vw;
  }

  @media screen and (max-width: 1023px) {
    font-size: 4.3vw;
  }
`

export const DescStyled = styled.div`
  font-size: 18px;
  line-height: 1.5;
  font-family: Montserrat;
  color: white;
  letter-spacing: 0.5px;
  margin-top: 10px;
  transition: all 1s ease;

  @media screen and (min-width: 2500px) {
    font-size: 30px;
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
    font-size: 24px;
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    font-size: 17px;
  }

  @media screen and (min-width: 1023px) and (max-width: 1439px) {
    font-size: 15px;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`

export const DescStyledClub = styled.div`
  font-size: 18px;
  line-height: 1.5;
  font-family: Montserrat;
  color: white;
  letter-spacing: 0.5px;
  margin-top: 10px;
  width: 55%;

  @media screen and (min-width: 2500px) {
    font-size: 30px;
    width: 65%;
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
    font-size: 24px;
    width: 60%;
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    font-size: 19px;
    width: 48%;
  }

  @media screen and (min-width: 1023px) and (max-width: 1439px) {
    font-size: 15px;
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
  }
`

export const BoxStyled = styled.div`
  // background-image: url(${(props) => props.background});
  // background-repeat: no-repeat;
  // background-size: contain;

  transition: all 1s ease;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;

  // border: 1px solid #be004f;

  @media screen and (min-width: 2500px) {
    padding-top: 40px;
    padding-left: 50px;
    padding-right: 50px;

    &.strategy {
      min-height: 30rem;
    }
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;

    &.strategy {
      min-height: 28rem;
    }
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;

    &.strategy {
      // min-height: 20rem;
    }
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;

    &.strategy {
      // min-height: 18rem;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;

    &.strategy {
      // min-height: 18rem;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1023px) {
    width: calc(100% - 60x);
    padding-top: 10px;
    padding-left: 55px;
    padding-right: 55px;

    &.strategy {
      // min-height: 19rem;
    }
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 60x);
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;

    &.strategy {
      // min-height: 18rem;
    }
  }

  @media screen and (max-width: 425px) {
    width: calc(100% - 60x);
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;

    // height: 50vh;
  }

  @media screen and (max-width: 375px) {
    width: calc(100% - 40x);
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;

    // height: 50vh;
  }

  @media screen and (max-width: 340px) {
    width: calc(100% - 20x);
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;

    // height: 60vh;
  }
`

export const Space = styled.div`
  margin-right: 5.5rem;

  @media screen and (max-width: 1023px) {
    margin-right: 0rem;
  }

`

export const CanvasButton = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  width: 100%;
  z-index: 1;
  margin-bottom: 3rem;

  &.strategy {
    justify-content: left;
  }

  &.team {
    justify-content: left;
  }

  @media screen and (min-width: 2500px) {
    margin-top: 55px;
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
    margin-top: 10px;
  }

  @media screen and (min-width: 1440px) and (max-width: 2499px) {
    margin-top: 2rem;
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    &.team {
      width: 100%;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1365px) {
    &.team {
      width: 100%;
      margin-top: 0rem;
    }

    &.strategy {
      width: 100%;
    }
  }

  @media screen and (max-width: 1023px) {
    &.team {
      justify-content: center;
      width: 100%;
    }

    &.strategy {
      justify-content: center;
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }

`

export const ButtonStrategyStyled = styled.img`
  height: 3.5vw;
  width: auto;
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
  }

  @media screen and (min-width: 2500px) {
    height: 4vw;
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
    height: 4vw;
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    height: 4vw;
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    height: 5vw;
  }

  @media screen and (min-width: 1023px) and (max-width: 1365px) {
    height: 6.5vw;
  }

  @media screen and (max-width: 1023px) {
    height: 9.5vw;
  }
`
export const ButtonCircleStyled = styled.img`
  height: 5.5vw;
  width: auto;
  margin: 0 30px;
  z-index: 1;

  @media screen and (min-width: 2500px) {
    margin: 0 50px;
    height: 6.5vw;
  }

  @media screen and (min-width: 1920px) and (max-width: 2499px) {
    height: 6.5vw;
    margin: 0 40px;
  }

  @media screen and (min-width: 1440px) and (max-width: 1919px) {
    height: 7vw;
  }

  @media screen and (min-width: 1366px) and (max-width: 1439px) {
    height: 8vw;
  }

  @media screen and (min-width: 1023px) and (max-width: 1365px) {
    height: 10vw;
  }

  @media screen and (min-width: 769px) and (max-width: 1022px) {
    height: 15.5vw;
  }

  @media screen and (max-width: 768px) {
    height: 16.5vw;
  }
`

export const HyperLinkStyled = styled.div`
  display: inline;
  color: white;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: #bf004e;
    text-decoration-thickness: 3px;
  }
}
`



const slide = keyframes`
  0%{
    border-color: #fb2c94;
    transform:translate(0,0);
  }
  20%{
    border-color: white;
    transform:translate(0.75rem,0.75rem);

    @media screen and (max-width: 420px) { 
      transform:translate(0.375rem, 0.375rem);
    }
  }
  20.1%,100%{
    border-color: #fb2c94;
  }
`

// #bf004d red
// #fb2c94 pink

export const ArrowsRightStyled = styled.div`
  position:relative;
  width:3rem;
  height:3rem;
  cursor: pointer;
  transform:rotate(-45deg);
  span{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    box-sizing:border-box;
    border:none;
    border-bottom:4px solid #fb2c94;
    transition: all 0.2s ease-in-out;
    border-right:4px solid #fb2c94;
    animation: ${slide} 4s linear infinite;
    &:nth-child(1){
      top:-1.5rem;
      left:-1.5rem;
      animation-delay:0.1s;
    }
    &:nth-child(2){
      top:-0.75rem;
      left:-0.75rem;
      animation-delay:0.8s;
    }
    &:nth-child(3){
      top:0;
      left:0;
      animation-delay:1.6s;
    }
    &:nth-child(4){
      top:0.75rem;
      left:0.75rem;
      animation-delay:2.4s;
    }
    &:nth-child(5){
      top:1.5rem;
      left:1.5rem;
      animation-delay:3.6s;
    }
    &:nth-child(6){
      transform:rotate(-45deg);
      border-bottom: 0px;
      border-right: 0px;
      top:1.5rem;
      left:1.5rem;
    }
  }
  &:hover {
    span {
      &:nth-child(1){
        animation: 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        top:0;
        left:0;
      }
      &:nth-child(2){
        animation: 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        top:0;
        left:0;
      }
      &:nth-child(3){
        animation: 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        top:0;
        left:0;
      }
      &:nth-child(4){
        animation: 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        top:0;
        left:0;
      }
      &:nth-child(5){
        animation: 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        top:0;
        left:0;
      }
      &:nth-child(6){
        animation: 0;
        transition: all 0s;
        border: 0px;
        top:-1rem;
        left:0rem;
        width:5rem;
        height:7rem;
      }
    }
  }

  @media screen and (max-width: 420px) {
    width:1.5rem;
    height:1.5rem;
    margin-right: 2rem;
  }
`

export const ArrowsLeftStyled = styled.div`
  position:relative;
  width:3rem;
  height:3rem;
  cursor: pointer;
  transform:rotate(135deg);
  span{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    box-sizing:border-box;
    border:none;
    transition: all 0.2s ease-in-out;
    border-bottom:4px solid #fb2c94;
    border-right:4px solid #fb2c94;
    animation: ${slide} 4s linear infinite;
    &:nth-child(1){
      top:-1.5rem;
      left:-1.5rem;
      animation-delay:0s;
    }
    &:nth-child(2){
      top:-0.75rem;
      left:-0.75rem;
      animation-delay:0.8s;
    }
    &:nth-child(3){
      top:0;
      left:0;
      animation-delay:1.6s;
    }
    &:nth-child(4){
      top:0.75rem;
      left:0.75rem;
      animation-delay:2.4s;
    }
    &:nth-child(5){
      top:1.5rem;
      left:1.5rem;
      animation-delay:3.6s;
    }
    &:nth-child(6){
      transform:rotate(-45deg);
      border-bottom: 0px;
      border-right: 0px;
      top:1.5rem;
      left:1.5rem;
    }
  }
  &:hover {
    span {
      &:nth-child(1){
        animation: 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        top:0;
        left:0;
      }
      &:nth-child(2){
        animation: 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        top:0;
        left:0;
      }
      &:nth-child(3){
        animation: 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        top:0;
        left:0;
      }
      &:nth-child(4){
        animation: 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        top:0;
        left:0;
      }
      &:nth-child(5){
        animation: 0;
        transition: all 0.2s ease-in-out;
        border-bottom: 4px solid white;
        border-right: 4px solid white;
        top:0;
        left:0;
      }
      &:nth-child(6){
        animation: 0;
        transition: all 0s;
        border: 0px;
        top:-1rem;
        left:0rem;
        width:5rem;
        height:7rem;
      }
    }
  }

  @media screen and (max-width: 420px) {
    width:1.5rem;
    height:1.5rem;
    margin-left: 2rem;
  }
`