import React from "react"

import {
  ColCenter,
  RowCenter,
  RowEnd,
  RowSpaceBetween,
  RowStart,
} from "../../components/general_styled"
import {
  FooterStyled,
  KiwamiSmall,
  KiwamiBig,
  SocialIconStyled,
  SocialWrapperStyled,
  OpenseaIconStyled,
} from "./styled"


import OpenseaLogo from "../../assets/images/desktop/opensea_icon_black.svg"
import Diamond from "../../assets/images/desktop/diamond.png"
import Line from "../../assets/images/desktop/line.png"
import useWindowDimensions from "../../utils/windowDimensions"
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function Footer() {
  const { width } = useWindowDimensions()

  return width < 1024 ? (
    <FooterStyled>
      <RowCenter className="w-100">
        <ColCenter>
          <RowCenter className="align-center mb-15px">
            <img src={Line} className="kiwami-line" alt="line" />
            <KiwamiBig>{"/// Kiwami ///"}</KiwamiBig>
            <img src={Line} className="kiwami-line" alt="line" />
          </RowCenter>
          <RowCenter>
            <SocialWrapperStyled className='hover-social' onClick={() => {
              setTimeout(() => { 
                window.open("https://t.co/OLUr5hLs2b")
              }, 500);
              }}>
              <SocialIconStyled icon={brands('discord')}/>
            </SocialWrapperStyled>
            <SocialWrapperStyled className='hover-social' onClick={() => {
              setTimeout(() => { 
                window.open("https://www.instagram.com/kiwaminft/")
              }, 500);
              }}>
              <SocialIconStyled icon={brands('instagram')}/>
            </SocialWrapperStyled>
            <SocialWrapperStyled className='hover-social' onClick={() => {
              setTimeout(() => { 
                window.open("https://twitter.com/KiwamiNFT")
              }, 500);
              }}>
              <SocialIconStyled icon={brands('twitter')}/>
            </SocialWrapperStyled>			
			<SocialWrapperStyled className="hover-social" onClick={() => {
			  setTimeout(() => {
				window.open("https://kiwami.ink");
			  }, 500);
			  }}>
			  <SocialIconStyled icon={brands("youtube")} />
			</SocialWrapperStyled>
          </RowCenter>
        </ColCenter>
      </RowCenter>
      <div className="kiwami-small">
        <RowEnd className=" align-center">
          <img src={Diamond} className="img-diamond" alt="diamond" />
          <KiwamiSmall>Kiwami 2023</KiwamiSmall>
        </RowEnd>
      </div>
    </FooterStyled>
  ) : (
    <FooterStyled>
      <RowSpaceBetween className="w-100">
        <RowStart>
          <SocialWrapperStyled className='hover-social' onClick={() => {
            setTimeout(() => { 
              window.open("https://t.co/OLUr5hLs2b")
            }, 500);
            }}>
            <SocialIconStyled icon={brands('discord')}/>
          </SocialWrapperStyled>
          <SocialWrapperStyled className='hover-social' onClick={() => {
            setTimeout(() => { 
              window.open("https://www.instagram.com/kiwaminft/")
            }, 500);
            }}>
            <SocialIconStyled icon={brands('instagram')}/>
          </SocialWrapperStyled>
          <SocialWrapperStyled className='hover-social' onClick={() => {
            setTimeout(() => { 
              window.open("https://twitter.com/KiwamiNFT")
            }, 500);
            }}>
            <SocialIconStyled icon={brands('twitter')}/>
          </SocialWrapperStyled>		  
		  <SocialWrapperStyled className="hover-social" onClick={() => {
			  setTimeout(() => {
				window.open("https://kiwami.ink");
			  }, 500);
			}}>
			<SocialIconStyled icon={brands("youtube")} />
		  </SocialWrapperStyled>
          </RowStart>
        <RowCenter className="align-center">
          <img src={Line} className="kiwami-line" alt="line" />
          <KiwamiBig>{"/// Kiwami ///"}</KiwamiBig>
          <img src={Line} className="kiwami-line" alt="line" />
        </RowCenter>
        <RowEnd className=" align-center">
          <img src={Diamond} className="img-diamond" alt="diamond" />
          <KiwamiSmall>Kiwami 2023</KiwamiSmall>
        </RowEnd>
      </RowSpaceBetween>
    </FooterStyled>
  )
}
