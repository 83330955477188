import { BackgroundStyled, ContentStyled } from "./styled"

import {
    HeaderStyled,
    DescStyled,
    DescStyledClub,
    BoxStyled,
} from "../../components/general_styled"
import {
    ALinkStyledText  } from "./styled";
import BgMobile from "../../assets/images/mobile/bg_club.png"
import BgClub from "../../assets/images/desktop/bg_club_high_res.jpg"
import useWindowDimensions from "../../utils/windowDimensions"

export default function Club() {
    const { width } = useWindowDimensions()

    return width > 1023 ? ( <
        BackgroundStyled id = "club"
        background = { BgClub } > { /* <Navbar underline="1" /> */ } <
        ContentStyled className = "half" >
        <
        HeaderStyled > Tengoku Club < /HeaderStyled> <
        BoxStyled >
        <
        DescStyledClub >
        Tengoku Club is located in the seedy underbelly of Shibuya, Tokyo. Every character from the Kiwami lore has been spotted at the Club. Whether they’re hanging on the patio after leaving the dance floor, mingling with rising Yakuza henchmen at the bar, drowning in the pulsating beats and spellbinding synth waves, spraypainting the emblems of their affiliation, or simply serving as club bouncer; Tengoku Club has found a way to unite them all. Rich or poor, artisan or salaryman; the Club sheds all egos. <
        br / >
        <
        br / >
        They may not realize it, but their stories and lives will soon be intermingled in ways they never imagined. <
        br / >

        <
        br / >
        <
        br / >
        <
        i > Welcome to Heaven. < /i> 
       < br / >

        <ALinkStyledText className="hover-2"
        href=" http://kiwami.ink"
      >
      Click here
      </ALinkStyledText>
        <
        /DescStyledClub> <
        /BoxStyled> <
        /ContentStyled> <
        /BackgroundStyled>
    ) : ( <
        BackgroundStyled id = "club"
        background = { BgMobile }
        className = "pt-50px pb-50px" > { /* <Navbar underline="1" /> */ } <
        ContentStyled className = "half" >
        <
        HeaderStyled > Tengoku Club < /HeaderStyled> <
        BoxStyled >
        <
        DescStyled >
        Tengoku Club is located in the seedy underbelly of Shibuya, Tokyo. Every character from the Kiwami lore has been spotted at the Club. Whether they’re hanging on the patio after leaving the dance floor, mingling with rising Yakuza henchmen at the bar, drowning in the pulsating beats and spellbinding synth waves, spraypainting the emblems of their affiliation, or simply serving as club bouncer; Tengoku Club has found a way to unite them all. Rich or poor, artisan or salaryman; the Club sheds all egos. <
        br / >
        <
        br / >
        They may not realize it, but their stories and lives will soon be intermingled in ways they never imagined. <
        br / >
        <
        br / >
        <
        br / >
        <
        i > Welcome to Heaven. < /i> 
        < br / >
 
        <ALinkStyledText className="hover-2"
        href=" http://kiwami.ink"
      >
      Click here
    </ALinkStyledText>
        <
        /DescStyled> <
        /BoxStyled> <
        /ContentStyled> <
        /BackgroundStyled>
    )
}