import React from "react";
import Navbar from "../../layouts/Navbar";
import { KiwamiLineStyled, BackgroundStyled } from "./styled";
import BgHome from "../../assets/images/desktop/bg_home.png";
import BgMobileHome from "../../assets/images/mobile/bg_home.png";
import useWindowDimensions from "../../utils/windowDimensions";
import { ColStart } from "../../components/general_styled";
import Club from "../Club";
import Team from "../Team";
import Rinen from "../Rinen";
import Strategy from "../Strategy";
import FAQ from "../FAQ";
import Footer from "../../layouts/Footer";

import { useLocation } from "react-router-dom";

export const resolveKiwamiLine = () => (
  <KiwamiLineStyled>
    {" "}
    {
      "/// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami /// Kiwami ///"
    }{" "}
  </KiwamiLineStyled>
);

export default function Home() {
  const { width } = useWindowDimensions();
  const location = useLocation();
  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(2));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  React.useEffect(() => {
    require("smoothscroll-polyfill").polyfill();
  }, []);
  return (
    <ColStart className="w-100">
      <Navbar />{" "}
      {/* <BackgroundStyled
                    placeholder={BgHomeLow}
                    src={BgHome}
                    debounce={0}
                  ></BackgroundStyled> */}{" "}
      <BackgroundStyled
        id="home"
        background={width > 1023 ? BgHome : BgMobileHome}
      ></BackgroundStyled>{" "}
      {resolveKiwamiLine()} <Club /> 
	  {resolveKiwamiLine()} <Rinen />{" "}
	  {/* {resolveKiwamiLine()} <Strategy /> */}
	  {resolveKiwamiLine()} <Team />{" "}
      {resolveKiwamiLine()} <FAQ />
      <Footer />
    </ColStart>
  );
}
