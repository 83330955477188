import React from "react"
import {
  ContentStyled,
  HeaderStyled,
  DescStyled,
  BoxStyled,
} from "../../components/general_styled"
import { BackgroundStyled } from "./styled"
import BgRinen from "../../assets/images/desktop/bg_strategy.jpg"
import BgMobileRinen from "../../assets/images/mobile/bg_strategy.jpg"
import useWindowDimensions from "../../utils/windowDimensions"

export default function Rinen() {
  const { width } = useWindowDimensions()
  return (
    <>
    <BackgroundStyled
      id="rinen"
      background={width > 1023 ? BgRinen : BgMobileRinen}
      className={width > 1023 ? "" : "pt-50px pb-50px mobile"}
    >
      {/* <Navbar underline="1" /> */}
      <ContentStyled className="half rinen">
        <HeaderStyled>Rinen</HeaderStyled>
        <BoxStyled>
          <DescStyled>
            Kiwami is a next-generation collective of artists, builders, and rule-breakers. Carefully designed in the metaverse & born in Japan, Kiwami is redefining what it means to be an authentic brand. For those who are Kiwami; extreme, loyal, driven, and ineffable⎯ you've found your people.
            <br />
            <br />
            Like any artistic movement, Kiwami is foremost driven by our community-first mindset. Every one of us brings our own stories, passions, and dreams. Kiwami is here to connect us and nurture the finest culture, rooted in a love for crypto-art that is built both with supreme artistic provenance in the metaverse, as well as in the real world.
            <br />
            <br />
            Kiwami is a collection of 10,000 NFTs, comprised of hundreds of unique visual traits designed by an anonymous Japanese team of street art mavericks. The aesthetic character is guided by cyberkinetic, urban pop art, and inspired by traditional Japanese elements.
          </DescStyled>
        </BoxStyled>
      </ContentStyled>
    </BackgroundStyled>
    </>
  )
}
