import React, { useState } from "react";

import {
  RowSpaceBetween,
  RowStart,
  RowEnd,
  RowCenter
} from "../../components/general_styled";
import {
  NavbarStyled,
  BodyNavbar,
  LinkWrapper,
  SocialIconStyled,
  SocialWrapperStyled,
  OpenseaIconStyled,
  ALinkStyledText,
  BodyLine,
  AnchorBodyLinkStyledText,
  ALinkStyledTextStatic
} from "./styled";
 import "./style.css"
import OpenseaLogo from "../../assets/images/desktop/opensea_icon_black.svg";
import Logo from "../../assets/images/mobile/logo.png";
import Hamburger from "../../assets/images/mobile/hamburger.png";
import Close from "../../assets/images/mobile/close.png";
import useWindowDimensions from "../../utils/windowDimensions";

import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Navbar(props) {
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);

  const onNavClicked = path => {
    if (width < 1120) {
      setIsOpen(false);
    }

    //   navigate(path)
  };

  const Dropdown = ({ label, value, options, onChange }) => {
    return (
      <label>
        {label}
        <select value={value} onChange={onChange}>
          {options.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </select>
      </label>
    );
  };
  return width < 1280 ? (
    <>
      <NavbarStyled className="mobile">
        <RowStart>
          <img
            src={Logo}
            alt="logo"
            className="ml-30px"
            height={25}
            style={{ height: "25px" }}
          />
        </RowStart>
        <RowEnd>
          <img
            src={isOpen ? Close : Hamburger}
            alt="hamburger"
            className={"mr-30px " + (isOpen && "rotate")}
            height={25}
            style={{ height: "25px" }}
            onClick={() => setIsOpen(!isOpen)}
          />
        </RowEnd>
      </NavbarStyled>

      <BodyNavbar className={isOpen && "open"}>
        {isOpen && (
          <>
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#home"
                onClick={onNavClicked}
              >
                Home
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#club"
                onClick={onNavClicked}
              >
                Club
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#rinen"
                onClick={onNavClicked}
              >
                Rinen
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#team"
                onClick={onNavClicked}
              >
                Team
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
			<LinkWrapper>
			  <AnchorBodyLinkStyledText
				className="hover-2"
				href="/manga"
				onClick={onNavClicked}
			  >
			  Manga
			  </AnchorBodyLinkStyledText>
			</LinkWrapper>
            <BodyLine />
			<LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="https://market.kiwami.app/" target="new"
              >
                Market
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
			{/*
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#strategy"
                onClick={onNavClicked}
              >
                Strategy
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
			*/}
			<LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="https://wami.kiwami.app" target="new"
              >
                WAMI
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <LinkWrapper>
              <AnchorBodyLinkStyledText
                className="hover-2"
                href="/#faq"
                onClick={onNavClicked}
              >
                FAQ
              </AnchorBodyLinkStyledText>
            </LinkWrapper>
            <BodyLine />
            <br />
            <RowCenter style={{ marginBottom: "30px" }}>
              <SocialWrapperStyled
                className="hover-social"
                onClick={() => {
                  setTimeout(() => {
                    window.open("https://t.co/OLUr5hLs2b");
                  }, 500);
                }}
              >
                <SocialIconStyled icon={brands("discord")} />
              </SocialWrapperStyled>
              <SocialWrapperStyled
                className="hover-social"
                onClick={() => {
                  setTimeout(() => {
                    window.open("https://www.instagram.com/kiwaminft/");
                  }, 500);
                }}
              >
                <SocialIconStyled icon={brands("instagram")} />
              </SocialWrapperStyled>
              <SocialWrapperStyled
                className="hover-social"
                onClick={() => {
                  setTimeout(() => {
                    window.open("https://twitter.com/KiwamiNFT");
                  }, 500);
                }}
              >
                <SocialIconStyled icon={brands("twitter")} />
              </SocialWrapperStyled>
			  <SocialWrapperStyled
				className="hover-social"
				onClick={() => {
				  setTimeout(() => {
					window.open("https://kiwami.ink");
				  }, 500);
				}}
			  >
				<SocialIconStyled icon={brands("youtube")} />
			  </SocialWrapperStyled>
            </RowCenter>
          </>
        )}
      </BodyNavbar>
    </>
  ) : (
    <NavbarStyled>
      <RowSpaceBetween>
        <RowStart>
          <LinkWrapper>
            <ALinkStyledText className="hover-2" href="/#home">
              Home
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText className="hover-2" href="/#club">
              Club
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText className="hover-2" href="/#rinen">
              Rinen
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText className="hover-2" href="/#team">
              Team
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText className="hover-2" href="/manga">
              Manga
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText className="hover-2" href="https://market.kiwami.app/" target="new">
              Market
            </ALinkStyledText>
          </LinkWrapper>
		  {/*
          <LinkWrapper>
            <ALinkStyledText className="hover-2" href="/#strategy">
              Strategy
            </ALinkStyledText>
          </LinkWrapper>
		  */}
		  <LinkWrapper>
            <ALinkStyledText className="hover-2" href="https://wami.kiwami.app" target="new">
              WAMI
            </ALinkStyledText>
          </LinkWrapper>
          <LinkWrapper>
            <ALinkStyledText className="hover-2" href="/#faq">
              FAQ
            </ALinkStyledText>
          </LinkWrapper>
          {/* <LinkWrapper>
            <ALinkStyledText className="hover-2" href="/manga/2">
              Chapter 2
            </ALinkStyledText>
          </LinkWrapper> */}
        </RowStart>
        <RowEnd>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://t.co/OLUr5hLs2b");
              }, 500);
            }}
          >
            <SocialIconStyled icon={brands("discord")} />
          </SocialWrapperStyled>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://www.instagram.com/kiwaminft/");
              }, 500);
            }}
          >
            <SocialIconStyled icon={brands("instagram")} />
          </SocialWrapperStyled>
          <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://twitter.com/KiwamiNFT");
              }, 500);
            }}
          >
            <SocialIconStyled icon={brands("twitter")} />
          </SocialWrapperStyled>
		  <SocialWrapperStyled
            className="hover-social"
            onClick={() => {
              setTimeout(() => {
                window.open("https://kiwami.ink");
              }, 500);
            }}
          >
            <SocialIconStyled icon={brands("youtube")} />
          </SocialWrapperStyled>
        </RowEnd>
      </RowSpaceBetween>
    </NavbarStyled>
  );
}
