import { useState } from "react";
import styled from "@emotion/styled";
import { Button as ConnectButton } from "../../shared/ConnectWallet";
import WordMark from "../../shared/WordMark";
import WalletModal from "../../shared/WalletModal";
import { getAbbreviatedAddress } from "../../utils/util";
import useWindowDimensions from "../../utils/windowDimensions";
import { useWeb3React } from "@web3-react/core";
import { useForm } from "react-hook-form";
import { ethers } from "ethers";
import { extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";

import {
  Box,
  ChakraProvider,
  SimpleGrid,
  Container,
  Skeleton,
  FormControl,
  Button,
  FormLabel,
  Input,
  Image,
  Stack,
  Select
} from "@chakra-ui/react";

import LogoSingle from "../../assets/images/desktop/logo_single_shaded.png";

import Snapback from "../../assets/images/desktop/merch/snapback.png";
import DadHat from "../../assets/images/desktop/merch/dad-hat.png";
import Hoodie from "../../assets/images/desktop/merch/hoodie.png";
import Skateboard from "../../assets/images/desktop/merch/skateboard.png";

import Navbar from "../../layouts/Navbar";

const myTheme = extendTheme(
  {
    colors: { ...theme.colors, brand: theme.colors.purple }
  },
  theme
);

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const TREASURY_ADDRESS = "0xBcAf5e757Ca1ef5F35fC2daBaDcb71dC7418A40D";

export const BackgroundStyled = styled.div`
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;

  min-height: 100vh;
  width: 100%;

  @media screen and (max-width: 1023px) {
    background-position: bottom;
  }
`;

const ProductText = styled.h3`
  font-family: akira;
  font-size: 18px;
  color: black;
`;

const BG_COLOR = "#ae2350";

const FieldLabel = ({ label = "" }) => {
  return (
    <FormLabel color={"white"} fontFamily="akira">
      {label}
    </FormLabel>
  );
};

const MerchOrder = () => {
  const { width } = useWindowDimensions();
  const { account, library } = useWeb3React();
  const [complete, setComplete] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onModalClose = () => setIsModalOpen(false);

  const {
    handleSubmit,
    register,
    formState: { isSubmitting }
  } = useForm({});

  const [dadHatSelected, setDadHatSelected] = useState(false);
  const [hoodieSelected, setHoodieSelected] = useState(false);
  const [snapbackSelected, setSnapbackSelected] = useState(false);
  const [skateboardSelected, setSkateboardSelected] = useState(false);

  const items = [
    {
      name: "The Pops",
      price: 0.025,
      image: DadHat,
      isSelected: dadHatSelected,
      setIsSelected: setDadHatSelected
    },
    {
      name: "Kiwami Rags",
      price: 0.035,
      image: Hoodie,
      isSelected: hoodieSelected,
      setIsSelected: setHoodieSelected
    },
    {
      name: "The Snapper",
      price: 0.025,
      image: Snapback,
      isSelected: snapbackSelected,
      setIsSelected: setSnapbackSelected
    },
    {
      name: "Kiwami Wowgo 2S Max",
      price: 0.55,
      image: Skateboard,
      isSelected: skateboardSelected,
      setIsSelected: setSkateboardSelected
    }
  ];

  const SIZES = ["S", "M", "L", "XL"];

  const onSubmit = async values => {
    const selectedItems = items
      .filter(item => item.isSelected)
      .map(item => item.name);
    const total = items.reduce(
      (partialSum, a) => (a.isSelected ? partialSum + a.price : partialSum),
      0
    );
    try {
      const signer = library.getSigner(account);
      const transactionHash = await signer.sendTransaction({
        to: TREASURY_ADDRESS,
        value: ethers.utils.parseEther(total.toString())
      });
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "order",
          ...values,
          selectedItems: selectedItems.join(),
          hash: transactionHash.hash,
          walletAddress: await signer.getAddress()
        })
      });
      setComplete(true);
      alert(
        "Once the metamask transaction is complete, your order is submitted. If the transaction fails, please refresh the page to try again."
      );
    } catch (err) {
      console.log("err", err);
      alert("try again later");
    }
  };

  const handleItemClick = item => {
    const { isSelected, setIsSelected } = item;
    setIsSelected(!isSelected);
  };

  const atLeastOneItemSelected = items.some(item => item.isSelected);

  return (
    <div>
      <Navbar />
      <Box
        paddingTop="30px"
        bg={BG_COLOR}
        minHeight="100vh"
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <WalletModal isOpen={isModalOpen} onClose={onModalClose} />
        <ChakraProvider theme={myTheme}>
          <Box
            position="absolute"
            top="200"
            left="0"
            right="0"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              boxSize={width > 1023 ? 550 : 300}
              src={LogoSingle}
              alt="Dan Abramov"
            />
          </Box>
          <Box>
            <Container py={8} centerContent>
              <ConnectButton
                color={"white"}
                onClick={() => setIsModalOpen(true)}
              >
                {account ? getAbbreviatedAddress(account) : "Connect wallet"}
              </ConnectButton>
              <WordMark />
              <Box
                maxW="5xl"
                mx="auto"
                px={{ base: "4", md: "8", lg: "12" }}
                py={{ base: "6", md: "8", lg: "12" }}
              >
                <SimpleGrid
                  columns={{ base: 1, sm: 2, lg: 4 }}
                  gap={{ base: "8", lg: "12" }}
                >
                  {items.map(item => (
                    <Stack spacing="4">
                      <Box
                        borderWidth={"3px"}
                        borderStyle="solid"
                        borderColor={
                          item.isSelected ? "#6B46C1" : "transparent"
                        }
                        bg={item.isSelected ? "#D6BCFA" : "bg-surface"}
                        boxShadow="lg"
                        borderRadius="lg"
                        p={{ base: "4", md: "6" }}
                        height={"350"}
                        position="relative"
                        className="group"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        cursor="pointer"
                        onClick={() => handleItemClick(item)}
                        textAlign="center"
                      >
                        <Image
                          src={item.image}
                          fallback={<Skeleton />}
                          maxHeight={200}
                          maxWidth={200}
                          alt={item.name}
                        />
                        <ProductText>{item.name}</ProductText>
                        <ProductText>{item.price} ETH</ProductText>
                      </Box>
                    </Stack>
                  ))}
                </SimpleGrid>
              </Box>
              <Stack
                spacing="5"
                px={{ base: "4", md: "6" }}
                py={{ base: "5", md: "6" }}
              >
                {hoodieSelected && (
                  <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                    <FormControl required id="size">
                      <FieldLabel label="Rag Size" />
                      <Select
                        {...register("size")}
                        bg="white"
                        required
                        placeholder="Select size"
                      >
                        {SIZES.map(size => (
                          <option value={size}>{size}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                )}
                <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                  <FormControl id="firstName">
                    <FieldLabel label="First Name" />
                    <Input {...register("firstName")} required />
                  </FormControl>
                  <FormControl id="lastName">
                    <FieldLabel label="Last Name" />
                    <Input {...register("lastName")} required />
                  </FormControl>
                </Stack>
                <FormControl required id="street">
                  <FieldLabel label="Street" />
                  <Input {...register("street")} required />
                </FormControl>
                <FormControl id="street2">
                  <FieldLabel label="Street 2" />
                  <Input {...register("street2")} />
                </FormControl>
                <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                  <FormControl id="city">
                    <FieldLabel label="City" />
                    <Input {...register("city")} required />
                  </FormControl>
                  <FormControl id="state">
                    <FieldLabel label="State / Province" />
                    <Input {...register("state")} />
                  </FormControl>
                  <FormControl id="zip">
                    <FieldLabel label="ZIP/ Postal Code" />
                    <Input {...register("zip")} />
                  </FormControl>
                  <FormControl id="country">
                    <FieldLabel label="Country" />
                    <Input {...register("country")} required />
                  </FormControl>
                </Stack>
                <Button
                  type="submit"
                  variant="primary"
                  bg="black"
                  isLoading={isSubmitting}
                  isDisabled={!account || complete || !atLeastOneItemSelected}
                >
                  Save
                </Button>
                <p
                  style={{
                    "text-align": "center",
                    fontSize: "50%",
                    color: "white"
                  }}
                >
                  Kiwami will not sell, disclose, or share your personal data
                  with any third parties. All data collected will be stored
                  securely (encrypted at rest and in transit with TLS) with
                  principles of least privilege implemented to ensure only
                  requisite individuals can access it. All data collected will
                  be permanently purged from any data stores upon completion of
                  your order.
                </p>
              </Stack>
            </Container>
          </Box>
        </ChakraProvider>
      </Box>{" "}
    </div>
  );
};

export default MerchOrder;
