import styled from "@emotion/styled";

const Text = styled.h3`
  font-family: akira;
  font-size: 28px;
  font-style: italic;
  background-color: white;
  color: #ae234f;
  padding-left: 12px;
  padding-right: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  transform: skewX(-15deg);
`;

const WordMark = ({}) => {
  return <Text>KIWAMI</Text>;
};

export default WordMark;
