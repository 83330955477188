import Navbar from "../../layouts/Navbar";
import { extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import styled from "@emotion/styled";

import useWindowDimensions from "../../utils/windowDimensions";

import { Box, ChakraProvider, Button, Image } from "@chakra-ui/react";

import { HeaderStyled } from "../../components/general_styled";

import manga from "../../assets/images/desktop/mangaMeta/KiwamiManga.png";
import chap1 from "../../assets/images/desktop/manga/1.jpeg";
import chap2 from "../../assets/images/desktop/manga-ch2/SamuraiNeko.jpg";
import chap3 from "../../assets/images/desktop/manga-ch3/Mil_0013.jpg";
import chap4 from "../../assets/images/desktop/manga-ch4/00.jpg";
import chap5 from "../../assets/images/desktop/manga-ch5/01.png";
import chap6 from "../../assets/images/desktop/manga-ch6/01.png";
import chap7 from "../../assets/images/desktop/manga-ch7/01.png";


import Player from "./player.js";

const Header = styled(HeaderStyled)`
  text-align: center !important;
  margin: 0px !important;
  font-size: 12px !important;
  font-family: "Akira";
  color: black;
  font-weight: bold;
`;
const HeaderImg = styled.div`
display: flex;
position: sticky;
margin-left: auto;
margin-right: auto;
`;


const myTheme = extendTheme(
    {
        colors: { ...theme.colors, brand: theme.colors.purple }
    },
    theme
);

const MOBILE_BREAKPOINT = 768;

const MOBILE_NAV_HEIGHT = 55;
const DESKTOP_NAV_HEIGHT = 66;

const BASE_PAGE_WIDTH = 300;

// const TITLE = "Chapter 1: The Story of Hime"
const TITLE_CHAP_1 = "The Story of Hime";
const TITLE_CHAP_2 = "Samurai Neko";
const TITLE_CHAP_3 = "AFRO LADY";
const TITLE_CHAP_4 = "TRUST THE SYSTEM";
const TITLE_CHAP_5 = "Free the Consciousnesses";
const TITLE_CHAP_6 = "SCARS OF WAR";
const TITLE_CHAP_7 = "NARCONET: DIGITAL RETALIATION";


const Manga = () => {
    const { width } = useWindowDimensions();

    const isMobile = width <= MOBILE_BREAKPOINT;

    const NAV_HEIGHT = isMobile ? MOBILE_NAV_HEIGHT : DESKTOP_NAV_HEIGHT;
    const TOP_OFFSET = NAV_HEIGHT;

    const PAGE_WIDTH = isMobile ? "80%" : BASE_PAGE_WIDTH;


    return (
        <ChakraProvider theme={myTheme}>
            <Navbar />
            <Box
                position="absolute"
                backgroundColor="#f1f5f9"
                top={55}
                left="0"
                right="0"
                display="flex"
                alignItems="center"
                flexDirection="column"
            >
                <   HeaderImg>
                    <img
                        style={{ marginTop: 30 }}
                        boxShadow="dark-lg"
                        src={manga}
                        width={100}
                    /> </ HeaderImg>
                {/* <Box
          position="sticky"
          top={isMobile ? "calc(100vh - 100px)" : "130px"}
          width={isMobile ? "auto" : "100%"}
          paddingLeft={isMobile ? "0" : "40px"}
          display="flex"
        >
          <Player />
        </Box> */}
                <Box marginTop={"50px"}>

                    <Box display="flex" alignItems="center" flexDirection={isMobile ? 'column' : "row"}>
                        <Box display="flex" alignItems="center" style={{ paddingBottom: 45 }} flexDirection="column">
                            <Header                   >
                                {TITLE_CHAP_1}
                            </Header>
                            <a href="manga/1">
                                <Image
                                    style={{

                                        marginLeft: 45,
                                        marginRight: 45
                                    }}
                                    boxShadow="dark-lg"
                                    src={chap1}
                                    width={PAGE_WIDTH}
                                /></a>
                        </Box>
                        <Box display="flex" alignItems="center" flexDirection="column" style={{ paddingBottom: 45 }} >
                            <Header>
                                {TITLE_CHAP_2}
                            </Header>
                            <a href="manga/2">
                                <Image
                                    style={{
                                        marginLeft: 45,
                                        marginRight: 45
                                    }}
                                    boxShadow="dark-lg"
                                    src={chap2}
                                    width={PAGE_WIDTH}
                                /> </a>
                        </Box>
                        <Box display="flex" alignItems="center" flexDirection="column" style={{ paddingBottom: 45 }} >
                            <Header>
                                {TITLE_CHAP_3}
                            </Header>
                            <a href="manga/3">
                                <Image
                                    style={{
                                        marginLeft: 45,
                                        marginRight: 45
                                    }}
                                    boxShadow="dark-lg"
                                    src={chap3}
                                    width={PAGE_WIDTH}
                                /> </a>
                        </Box>
                    </Box>
					
                    <Box display="flex" alignItems="center" flexDirection={isMobile ? 'column' : "row"}>
                        <Box display="flex" alignItems="center" flexDirection="column" style={{ paddingBottom: 45 }} >
                            <Header>
                                {TITLE_CHAP_4}
                            </Header>
                            <a href="manga/4">
                                <Image
                                    style={{
                                        marginLeft: 45,
                                        marginRight: 45
                                    }}
                                    boxShadow="dark-lg"
                                    src={chap4}
                                    width={PAGE_WIDTH}
                                /> </a>
                        </Box>
						<Box display="flex" alignItems="center" flexDirection="column" style={{ paddingBottom: 45 }} >
                            <Header>
                                {TITLE_CHAP_5}
                            </Header>
                            <a href="manga/5">
                                <Image
                                    style={{
                                        marginLeft: 45,
                                        marginRight: 45
                                    }}
                                    boxShadow="dark-lg"
                                    src={chap5}
                                    width={PAGE_WIDTH}
                                /> </a>
                        </Box>
						<Box display="flex" alignItems="center" flexDirection="column" style={{ paddingBottom: 45 }} >
                            <Header>
                                {TITLE_CHAP_6}
                            </Header>
                            <a href="manga/6">
                                <Image
                                    style={{
                                        marginLeft: 45,
                                        marginRight: 45
                                    }}
                                    boxShadow="dark-lg"
                                    src={chap6}
                                    width={PAGE_WIDTH}
                                /> </a>
                        </Box>
                    </Box>
					
                    <Box display="flex" alignItems="center" flexDirection={isMobile ? 'column' : "row"}>
                        <Box display="flex" alignItems="center" flexDirection="column" style={{ paddingBottom: 45 }} >
                            <Header>
                                {TITLE_CHAP_7}
                            </Header>
                            <a href="manga/7">
                                <Image
                                    style={{
                                        marginLeft: 45,
                                        marginRight: 45
                                    }}
                                    boxShadow="dark-lg"
                                    src={chap7}
                                    width={PAGE_WIDTH}
                                /> </a>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ChakraProvider>
    );
};

export default Manga;
