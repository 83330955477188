import { useWeb3React, Web3ReactProvider } from "@web3-react/core";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// import Club from "./pages/Club"
import Home from "./pages/Home";
import Gallery from "./pages/gallery";
import HappiReservation from "./pages/UserInfo";
import MerchOrder from "./pages/MerchOrder";
import Manga1 from "./pages/Manga"; 
import Manga from "./pages/Manga/meta"; 
import Manga2 from "./pages/Manga/index2";
import Manga3 from "./pages/Manga/index3";
import Manga4 from "./pages/Manga/index4";
import Manga5 from "./pages/Manga/index5";
import Manga6 from "./pages/Manga/index6";
import Manga7 from "./pages/Manga/index7";
import Mint from "./pages/Mint";
import Verify from "./pages/Verify";
import WhitelistedMint from "./pages/WhitelistedMint";
// import Rinen from "./pages/Rinen"
// import Strategy from "./pages/Strategy"
// import Team from "./pages/Team"
import getLibrary from "./utils/getLibrary";
import { useEagerConnect, useInactiveListener } from "./utils/hooks";
import useTransactionFloaters from "./shared/useTransactionFloaters";

function Web3Container() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Router />
    </Web3ReactProvider>
  );
}

function Router() {
  const { connector, error } = useWeb3React();
  const { transactionHandlers } = useTransactionFloaters();

  // console.log("connector: " + JSON.stringify(connector));
  // console.log("error", error);

  // logic to connect to wallet if already exists, e.g. on refresh
  const [activatingConnector, setActivatingConnector] = useState(null);
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);
  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          {/* <Route path="/happiReservation" element={<HappiReservation />} /> */}
          {/* <Route path="/merch" element={<MerchOrder />} /> */}
          <Route
            path="/mint"
            element={<Mint handlers={{ transactionHandlers }} />}
          />
          <Route path="/manga" element={<Manga />} />   
          <Route path="/manga/1" element={<Manga1 />} />
          <Route path="/manga/2" element={<Manga2 />} />
          <Route path="/manga/3" element={<Manga3 />} />
          <Route path="/manga/4" element={<Manga4 />} />
          <Route path="/manga/5" element={<Manga5 />} />
          <Route path="/manga/6" element={<Manga6 />} />
          <Route path="/manga/7" element={<Manga7 />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/whitelisted" element={<WhitelistedMint />} />
          {/* <Route path="/home" element={<Home />} />
          <Route path="/club" element={<Club />} />
          <Route path="/strategy" element={<Strategy />} />
          <Route path="/rinen" element={<Rinen />} />
          <Route path="/team" element={<Team />} /> */}
        </Routes>
      </BrowserRouter>
      {/* <TransactionFloaters transactions={transactions} transactionHandlers={transactionHandlers} /> */}
    </>
  );
}

export default Web3Container;
