import BgHome from "../../assets/images/desktop/bg_home.png";
import BgMobileHome from "../../assets/images/mobile/bg_home.png";
import useWindowDimensions from "../../utils/windowDimensions";
import { useWeb3React } from "@web3-react/core";
import { BackgroundStyled } from "../Home/styled";
import ConnectWallet from "../../shared/ConnectWallet";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import { useForm } from "react-hook-form";
import { ethers } from "ethers";
import React, { useState } from "react";

import {
  Box,
  FormControl,
  Button,
  Divider,
  Flex,
  FormLabel,
  Input,
  Stack,
  useColorModeValue,
  Alert,
  Select,
  AlertIcon
} from "@chakra-ui/react";

const myTheme = extendTheme(
  {
    colors: { ...theme.colors, brand: theme.colors.purple }
  },
  theme
);
const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};
const TREASURY_ADDRESS = "0xBcAf5e757Ca1ef5F35fC2daBaDcb71dC7418A40D";

const UserInfo = () => {
  const [complete, setComplete] = useState(false);
  const { width } = useWindowDimensions();
  const { account, library } = useWeb3React();
  const {
    getValues,
    setValue,
    handleSubmit,
    register,
    formState: { isSubmitting }
  } = useForm({});

  const onSubmit = async values => {
    // Save data to DB or google sheet here
    // After save -> trigger MM pop up for transaction
    try {
      const signer = library.getSigner(account);
      //  console.log(signer)

      const transactionHash = await signer.sendTransaction({
        to: TREASURY_ADDRESS,
        value: ethers.utils.parseEther(".05")
      });
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "order",
          ...values,
          hash: transactionHash.hash,
          walletAddress: await signer.getAddress()
        })
      });
      setComplete(true);
    } catch (e) {
      alert("try again later");
    }
  };
  const boxShadow = useColorModeValue("sm", "sm-dark");

  return (
    <>
      <div
        style={{
          position: "absolute",
          width: "100%",
          top: 0,
          backgroundColor: "white"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <ConnectWallet />
        </div>
      </div>

      <BackgroundStyled
        id="home"
        background={width > 1023 ? BgHome : BgMobileHome}
        style={{
          paddingTop: 100,
          paddingBottom: 100,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <ChakraProvider theme={myTheme}>
          <Box
            as="form"
            onSubmit={handleSubmit(onSubmit)}
            bg="bg-surface"
            boxShadow={boxShadow}
            borderRadius="lg"
            width={{ sm: "90%", lg: "3xl" }}
          >
            <p
              style={{
                "text-align": "center"
              }}
            >
              Place an order to secure your Kiwami Happi. As soon as you enter
              your shipping details and hit save, metamask will prompt you to
              send 0.05 ETH to the Kiwami Treasury to fulfill your order.
            </p>
            <Divider />

            {!account && (
              <Alert status="warning" borderTopRadius="lg">
                <AlertIcon />
                You must connect your MetaMask Account to proceed
              </Alert>
            )}
            {complete && (
              <Alert status="info" borderTopRadius="lg">
                <AlertIcon />
                Thanks for your order
              </Alert>
            )}
            <Stack
              spacing="5"
              px={{ base: "4", md: "6" }}
              py={{ base: "5", md: "6" }}
            >
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl required id="size">
                  <FormLabel>Size</FormLabel>
                  <Input
                    {...register("size")}
                    defaultValue="Free Size"
                    disabled
                  />
                </FormControl>
                <FormControl id="gender">
                  <FormLabel>Gender</FormLabel>
                  <Select
                    required
                    {...register("gender", {
                      setValueAs: v => {
                        if (v === "Female") {
                          setValue("size", "M");
                        } else {
                          setValue("size", "Free Size");
                        }
                        return v;
                      }
                    })}
                    placeholder="Select option"
                  >
                    <option value="Female">Female</option>
                    <option value="Male">Male</option>
                  </Select>
                </FormControl>
              </Stack>
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="firstName">
                  <FormLabel>First Name</FormLabel>
                  <Input {...register("firstName")} required />
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input {...register("lastName")} required />
                </FormControl>
              </Stack>
              <FormControl required id="street">
                <FormLabel>Street</FormLabel>
                <Input {...register("street")} required />
              </FormControl>

              <FormControl id="street2">
                <FormLabel>Street 2 </FormLabel>
                <Input {...register("street2")} />
              </FormControl>
              <Stack spacing="6" direction={{ base: "column", md: "row" }}>
                <FormControl id="city">
                  <FormLabel>City</FormLabel>
                  <Input {...register("city")} required />
                </FormControl>
                <FormControl id="state">
                  <FormLabel>State / Province</FormLabel>
                  <Input {...register("state")} />
                </FormControl>
                <FormControl id="zip">
                  <FormLabel>ZIP/ Postal Code</FormLabel>
                  <Input {...register("zip")} />
                </FormControl>
                <FormControl id="country">
                  <FormLabel>Country</FormLabel>
                  <Input {...register("country")} required />
                </FormControl>
              </Stack>
            </Stack>
            <Divider />
            <Flex direction="row-reverse" py="4" px={{ base: "4", md: "6" }}>
              <Button
                type="submit"
                variant="primary"
                isLoading={isSubmitting}
                isDisabled={!account || complete}
              >
                Save
              </Button>
            </Flex>
            <Divider />

            <p
              style={{
                "text-align": "center",
                fontSize: "50%"
              }}
            >
              Kiwami will not sell, disclose, or share your personal data with
              any third parties. All data collected will be stored securely
              (encrypted at rest and in transit with TLS) with principles of
              least privilege implemented to ensure only requisite individuals
              can access it. All data collected will be permanently purged from
              any data stores upon completion of your order.
            </p>
          </Box>
        </ChakraProvider>
      </BackgroundStyled>
    </>
  );
};

export default UserInfo;
